import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import TiltShape from '../TiltShape';
import {
  BannerWrapper,
  DiscountWrapper,
  DiscountLabel,
  Star,
  GrayStar,
  BannerItem,
  BannerLink,
  TestimonialWrapper,
  Logo,
} from './banner.style';
import { ic_cloud_download } from 'react-icons-kit/md/ic_cloud_download';
import { Link } from 'gatsby';

import g2LogoURL from '../../../../landing-gatsby/src/images/logos/g2.png';
import chromeLogoURL from '../../../../landing-gatsby/src/images/logos/chrome.png';
import downloadLogoURL from '../../../../landing-gatsby/src/images/logos/download.png';

const BannerSection = ({
  row,
  contentWrapper,
  discountAmount,
  discountText,
  title,
  description,
  imageWrapper,
  buttonWrapper,
  button,
  fillButton,
  pageContext,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "image/saasModern/banner-image.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1170, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const { t } = useTranslation();

  return (
    <BannerWrapper id="banner_section">
      <TiltShape />
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <DiscountWrapper>
              <DiscountLabel>
                <Text
                  {...discountText}
                  content={t('All business features free up to 10 users')}
                />
              </DiscountLabel>
            </DiscountWrapper>
            <Heading
              {...title}
              content={t(
                'Open Source Self Hosted Password Manager for Companies'
              )}
              as="h1"
            />
            <Text
              {...description}
              content={t('Secure self-hosted solution for businesses')}
            />
            <Box {...buttonWrapper}>
              <a href="https://www.psono.pw/" target="_blank" rel="noreferrer">
                <Button {...fillButton} title={t('ACCESS PSONO FREE')} />
              </a>
              <Link to={`${pageContext.langPathPrefix}/download`}>
                <Button
                  {...button}
                  title={t('DOWNLOAD')}
                  icon={<Icon icon={ic_cloud_download} size={30} />}
                  iconPosition="left"
                />
              </Link>
            </Box>
          </Box>
          <Box {...imageWrapper}>
            <Fade bottom>
              <Image
                fluid={Data.bannerImage.childImageSharp.fluid}
                alt={t('open source password manager')}
              />
            </Fade>
          </Box>
        </Box>
        <Fade bottom>
          <TestimonialWrapper>
            {/* G2 Rating */}
            <BannerItem>
              <BannerLink href="https://www.g2.com/products/psono-password-manager/reviews">
                <Logo src={g2LogoURL} alt="G2 Logo" />
                <Star>★</Star>
                <Star>★</Star>
                <Star>★</Star>
                <Star>★</Star>
                {/*<Star>★</Star>*/}
                <GrayStar>☆</GrayStar>
                (4.7 / 5)
              </BannerLink>
            </BannerItem>

            {/* Chrome Appstore Rating */}
            <BannerItem>
              <BannerLink href="https://chrome.google.com/webstore/detail/psono-free-password-manag/eljmjmgjkbmpmfljlmklcfineebidmlo">
                <Logo src={chromeLogoURL} alt="Chrome Logo" />
                <Star>★</Star>
                <Star>★</Star>
                <Star>★</Star>
                <Star>★</Star>
                {/*<Star>★</Star>*/}
                <GrayStar>☆</GrayStar>
                (4.5 / 5)
              </BannerLink>
            </BannerItem>

            {/* Download Count */}
            <BannerItem>
              <BannerLink href="https://hub.docker.com/u/psono">
                <Logo src={downloadLogoURL} alt="Download Icon" />
                {t('Over 2 million Downloads')}
              </BannerLink>
            </BannerItem>
          </TestimonialWrapper>
        </Fade>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '80%', '55%', '50%'],
    mb: '40px',
  },
  imageWrapper: {
    width: '100%',
  },
  title: {
    fontSize: ['24px', '32px', '40px', '42px', '46px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  description: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#fff',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'center',
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#00865b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#fff',
    mb: 0,
    as: 'span',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 10px', '8px 10px'],
    colors: 'secondaryWithBg',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px',
  },
  button: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    color: '#fff',
    colors: 'secondary',
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
};

export default BannerSection;
